.quotaion-container {
  background-color: #f1fdff;
  /* height: 100%; */
  display: flex;
  margin: 0;
}
.no-shadow-card {
  background-color: #fff;
  border-radius: 10px;
  margin: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 233px;
  max-width: 420px;
  /* padding: 20px; */
}

.shadow-card {
  background-color: #fff;
  border-radius: 10px;
  margin: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 233px;
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.custom-modal {
  max-width: 800px; /* Adjust the max-width to your desired size */
  width: 80%; /* Adjust the width as a percentage of the parent container or your preference */
}

.left-section {
  flex: 20%;
  margin: 0px;
}
.main-section {
  flex: 70%;
  margin: 0px;
  /* background-color: green; */
}
.linear-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.small-text {
  color: #a3a3a3;
  font-size: 12px;
}

.semibold-text {
  color: black;
  font-size: 16px;
  font-weight: 500px;
}

.idvText {
  margin-left: 40px;
}

p {
  margin: 0;
}

.line {
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  padding: 0px;
}

.edit-btn {
  color: #00b0cb;
  font-size: 12px;
  font-weight: 700;
}

.price-div {
  background-color: #00b0cb;
  width: 160px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-txt {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.sub-headline {
  display: flex;
  justify-content: space-between;
  color: #455a64;
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
}

.headline {
  font-size: 23px;
  font-weight: 500px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.input-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  flex-wrap: wrap;
}

.button-txt {
  color: #757575;
  font-size: 17px;
  font-weight: 400;
}
.styled-input {
  max-width: 360px;
  width: 100%;
  height: 50px;
  border-radius: 0.625rem;
  border: 1px solid #00b0cb;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.brand-image {
  width: 115px;
  /* height: 45px; */
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  object-fit: contain;
}

/* checkbox-rect */
.box {
  width: 30%;
}
.box .form-check-label {
  font-size: 18px;
}
.checkbox-rect input[type="checkbox"] {
  display: none;
}
.checkbox-rect input[type="checkbox"] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  font: 18px/25px "Open Sans", Arial, sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.checkbox-rect input[type="checkbox"]:hover + label:hover {
  color: rgb(23, 86, 228);
}
.checkbox-rect input[type="checkbox"]:hover + label:before {
  background: #50565a;
  box-shadow: inset 0px 0px 0px 2px #f7f2f2;
}
.checkbox-rect input[type="checkbox"] + label:last-child {
  margin-bottom: 0;
}
.checkbox-rect input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 1.4em;
  height: 1.4em;
  /* border: 1px solid #343a3f; */
  border-radius: 0.2em;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.2s, background 0.2s ease-in-out;
  transition: all 0.2s, background 0.2s ease-in-out;
  background: #00b0cb57;
}
.checkbox-rect input[type="checkbox"]:checked + label:before {
  width: 1.3em;
  height: 1.3em;
  border-radius: 0.2em;
  border: 2px solid #fff;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background: #50565a;
  box-shadow: 0 0 0 1px #000;
}
/* checkbox-rect end */

@media only screen and (max-width: 860px) {
  .quotaion-container {
    flex-direction: column;
  }
  .left-section {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 550px) {
  .minMaxIdvRemove {
    display: none;
  }

  .price-div {
    width: 33%;
    height: 40px;
    max-width: 80px;
  }
  .price-txt {
    font-size: 18px;
  }
  .shadow-card {
    margin: 15px 0;
    padding: 10px;
  }

  .brand-image {
    width: 33%;
    max-width: 70px;
  }

  .idvText {
    margin-left: 0;
    width: 33%;
  }

  .left-section-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .no-shadow-card {
    width: 100%;
  }
}

.adjusted-text {
  font-size: 1rem !important;
}

.disable_addon {
  pointer-events: none;
  opacity: 0.6;
}

/* new-css */
.invoice_sec {
  background: #fff;
  border: 1px solid rgba(37, 56, 88, 0.12);
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
}
.sec_heading {
  font-size: 18px;
  font-weight: 600;
}
.sec_text {
  display: inline-block;
  font-size: 14px;
}
.line {
  border: 1px solid rgba(37, 56, 88, 0.12);
  /* margin-right: -16px;
  margin-left: -16px;
  margin-bottom: 16px;
  margin-top: 16px; */
  margin: 16px auto;
}
.invoice_content {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}
.invoce_des {
  display: flex;
  align-items: center;
  text-align: right;
  font-weight: 500;
  cursor: pointer;
  justify-content: flex-end;
  font-size: 14px;
}
.main-quote-list {
  background: #0a549917;
  /* display: flex; */
  padding: 130px 80px 50px;
}
.edit {
  text-decoration: none;
  color: #00b0cb;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
}
.css-319lph-ValueContainer {
  font-size: 15px;
}
.css-2613qy-menu input {
  font-size: 14px !important;
  background-color: #fff !important;
}
.addon .accordion-item .accordion-button {
  font-weight: 600;
  font-size: 16px;
  padding: 8px 20px;
  background: #cbedf2;
  border-radius: 8px;
  color: #000;
}
.addon .accordion-item .acc {
  font-weight: 600;
  font-size: 16px;
  padding: 8px 20px;
  background: #cbedf2;
  border-radius: 8px;
  color: #000;
}
.addon .accordion-body {
  padding: 20px 0px 0px;
}
.product_box {
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  /* gap: 10px 25px; */
  /* padding: 0 10px; */
}
.product_list {
  display: flex;
  gap: 7px;
  /* margin-right: 25px; */
  align-items: baseline;
  width: 33%;
}
.addon .accordion-item {
  margin-top: 15px;
  border: none;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  color: #000;
  border-radius: 8px;
}
.accordion-button:not(.collapsed)::after {
  background: url(/public/assets/images/above_arrow.svg) 0 0 no-repeat;
  width: 20px;
  height: 20px;
}
.accordion-button::after {
  background: url(/public/assets/images/arrow_down.svg) 0 0 no-repeat;
  width: 20px;
  height: 20px;
}
.product_text {
  font-size: 15px;
}
.product_list {
  display: flex;
  gap: 7px;
  /* margin-right: 25px; */
  align-items: center;
  width: 25%;
}

@media (max-width: 1199px) {
  .main-quote-list {
    padding: 80px 20px 30px;
  }
}

@media (max-width: 991px) {
  .main-quote-list {
    display: block;
    padding: 100px 10px 20px;
  }
  .invoice_sec {
    border-radius: 0;
    margin-bottom: 7px;
  }
  .sec_heading {
    font-size: 16px;
  }
  .invoice_list {
    display: flex;
    /* justify-content: space-between; */
    gap: 15px;
  }

  .invoice_list .row {
    width: 34%;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    position: relative;
  }
  .invoice_year .row {
    width: 50%;
  }

  .invoice_list .row .invoice_content {
    margin-bottom: 0;
  }
  .invoice_list .row::after {
    position: absolute;
    content: "";
    background: rgba(37, 56, 88, 0.12);
    top: 0;
    left: -40px;
    width: 1px;
    height: 100%;
  }
  .invoice_list .row .invoce_des {
    justify-content: flex-start;
    text-align: left;
  }
}

@media (max-width: 575px) {
  .main-quote-list {
    display: block;
    padding: 80px 10px 20px;
  }
  .invoice_content {
    font-size: 13px;
  }
  .invoice_list .row::after {
    left: -10px;
  }
  .invoice_year .row::after {
    left: -30px;
  }
}

.plan_modal {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}
.plan_modal h1 {
  font-size: 12px;
  padding: 0 12px;
}
.plan_modal .btn {
  width: auto;
  padding: 8px;
  font-size: 13px;
  white-space: nowrap;
}

.ul-percent {
  text-decoration: none;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  flex-wrap: wrap;
}
.ul-percent p {
  margin-right: 10px;
  margin-bottom: 10px;
}
.ul-percent p label {
  border: 1px solid grey;
  padding: 4px 12px;
  border-radius: 20px;
}
.ul-percent p .selected {
  border: 3px solid #00b0cb !important;
}
.ul-percent p label input {
  margin-right: 10px;
}
